@import "limitless/css/icons/icomoon/styles.min.css";
@import "limitless/css/icons/fontawesome/styles.min.css";
@import "limitless/css/bootstrap_limitless.css";
@import "limitless/css/layout.css";
@import "limitless/css/components.css";
@import "limitless/css/colors.css";
@import "rating";

@font-face {
  font-family: open-sans-light;
  src: url(./fonts/open-sans.light.ttf)
}

@font-face {
  font-family: metropolis;
  src: url(./fonts/metropolis.regular.otf)
}

@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url('./fonts/manrope/Manrope-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Manrope';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src: url('./fonts/manrope/Manrope-Bold.woff') format('woff');
}

$box-shadow : 0 2px 17px 0 #e9e9e9;
$box-radius: 10px;

body {
  font-family: metropolis;
  background-color: white;
  font-size: 16px;
}

/** IPhone zoom issue */
input[type='text'], input[type='text'].form-control,
textarea, textarea.form-control {
  font-size: 16px;
}

.btn-primary, .btn-light {
  border: none;
  font-size: 21px;
  font-family: inherit;
  border-radius: 10px;
  padding: 8px 24px;
}

.btn-primary {
  background: #3B4AFF;
}

ul {
  padding: 0;
}

ul li {
  list-style: none;
}

.content-body ul li {
  list-style: disc inside none;
}

footer {
  position: relative;

  .scroll-to-top-btn {
    position: absolute;
    bottom: 14px;
    right: 0;
  }
}

section {
  margin-bottom: 40px;
}

.section-heading {
  text-align: center;
  text-transform: uppercase;
  color: #3B4AFF;
  font-family: 'Manrope';
  font-weight: 700;
}

.navbar-toggler {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 1000;

  .icon-paragraph-justify3 {
    font-size: 30px;
  }
}

.section-heading {
  margin-bottom: 24px;
}

header {
  color: rgba(51, 51, 51, 0.85);
  background-color: #fff;
  border-bottom-color: rgba(0, 0, 0, 0.125);

  .logo img {
    width: 100%;
    max-width: 250px;
  }
}

.card {
  border: none;
}

footer {
  margin-top: 100px;
}

.container {
  background: white;
}

header .header-text {
  margin-top: 20px;
  line-height: 40px;
  font-size: 16px;
}

.navbar-brand img {
  height: 100px;
  margin-left: 63px;
}

.sections section:not(:first-child) {

  .section-heading {
    margin-top: 82px;
  }
}

.home {
  text-transform: uppercase;

  .summery {
    padding-top: 42px;
    padding-bottom: 15px;

    p {
      line-height: 32px;
      @media only screen and (min-width: 480px) {
        font-size: 20px;
        line-height: 40px;
      }

    }

    .guest-name {
      font-size: 30px;
      @media only screen and (min-width: 480px) {
        font-size: 50px;
      }
    }

    .guest-name, .apartment-name {
      font-family: 'Manrope';
      font-weight: 700;
      color: #3B4AFF;
    }
  }
  a.map {
    margin-right: 17px;
  }
  .map img {
    width: 40px;
    @media only screen and (min-width: 480px) {
      width: 44px;
    }
  }

  .address-panel {
    padding: 18px;

    .address-map {
      width: 100%;
      text-align: center;

      a {
        cursor: pointer;
      }
    }

    .address {
      text-align: center;
      padding-top: 20px;
      @media only screen and (min-width: 480px) {
        font-size: 22px;
      }
    }
  }

}

#weather {
  padding: 24px;
  color: white;
  font-size: 24px;
  .current-day-description, .day {
    font-size: 16px;
  }
}

.tours {

  img {
    max-width: 100%;
  }

  .lSAction > .lSPrev {
    left: 0;
  }

  .lSAction > .lSNext {
    right: 0;
  }

  .lSAction > a {
    top: 52%;
    background-image: none;
    opacity: 0.6;

    i {
      font-size: 20px;
    }
  }
}

.menu-secondary {
  .menu-item  {
    border-radius: 10px;
    margin-bottom: 10px;
    box-shadow: 0 2px 17px 0 #e9e9e9;
    padding: 10px;
    a {
      border-radius: 10px;
      color: #333;
      cursor: pointer;

      .row {
        min-height: 40px;
      }

      img {
        width: 36px;
        @media only screen and (min-width: 480px) {
          width: 46px;
        }
      }

      .label {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0.625rem;
        font-size: 12px;
        text-align: right;

        @media only screen and (min-width: 480px) {
          font-size: 22px;
        }
      }
    }
  }

}

.review {
  .center-label {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
  }
}

.content-body {
  img, iframe {
    max-width: 100%;
  }
}

section#message {
  box-shadow: 0 2px 17px 0 #e9e9e9;
  padding: 15px;
  border-radius: 10px;
}

ul.message-list {
  padding: 10px;
  max-height: 300px;
  overflow-y: auto;

  li {
    margin-bottom: 15px;
  }
}

.chat-bubble {
  position: relative;
  border-radius: 4px;
  padding: 15px;
  max-width: 96%;
}

.chat-bubble-right {
  background:  #3B4AFF;
  float: right;
  clear: both;
  color: white;
}

.chat-bubble-right:after {
  content: '';
  position: absolute;
  right: 0;
  top: 30%;
  width: 0;
  height: 0;
  border: 7px solid transparent;
  border-left-color:  #3B4AFF;
  border-right: 0;
  margin-top: -7px;
  margin-right: -7px;
}

.chat-bubble-left {
  background: #41F793;
  float: left;
}

.chat-bubble-left:after {
  content: '';
  position: absolute;
  left: 0;
  top: 30%;
  width: 0;
  height: 0;
  border: 7px solid transparent;
  border-right-color: #41F793;
  border-left: 0;
  margin-top: -7px;
  margin-left: -7px;
}
.cart-items-mobile .label {
  line-height: 35px;
}

.cart-items {
  th {
    text-transform: uppercase;
    border-bottom: 1px solid #41F793 !important;
  }
}
.product-list {

  font-size: 16px;

  @media only screen and (min-width: 480px) {
    font-size: 24px;
  }

  .product-list-item {
    padding: 10px;
    text-transform: uppercase;
    box-shadow: $box-shadow;
    margin-bottom: 10px;
    border-radius: $box-radius;
  }
  img {
    max-width: 42px;
  }
  .price {
    margin-right: 10px;
    padding-top: 4px;
    float: left;
  }
  .name {
    min-height: 66px;
  }
}

div#card-element, div#stripe-connect-card-element {
  max-width: 450px;
  margin-top: 20px;
}
.stripe-connect-ideal  {
  max-width: 266px;
  margin-top: 20px;
}
div#payment-element-errors {
  font-size: 12px;
  margin-top: 8px;
}
#cart {
  margin-top: 38px;
}

section#weather {
  background-image: url('/images/weather-bg.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  text-align: center;
  border-radius: 23px;
}

.products {
  .product-list-item {
    .name {
      max-width: 55%;
    }
    .add-to-cart-btn {
      padding-top: 0;
    }
    .add-to-cart-btn:hover {
      background: white;
      box-shadow: none !important;
    }
  }
}

.cart-section {
  box-shadow: $box-shadow;
  border-radius: $box-radius;
  padding: 15px;
}

.tours {
  a {
    color : #333;
  }
  a:hover {
    color: #333;
  }
  img {
    border-radius: $box-radius;
  }
}

#review {
  border-radius: $box-radius;
  box-shadow: $box-shadow;
  padding: 15px;
}

.bluredMap {
  height: 600px;
  width: 100%;
  position: relative;
  overflow: hidden;

  .bluredSvg {
    position: absolute;
    left: 0;
    top: 0;
    -webkit-filter: blur(5px);
    filter: blur(5px);
  }

  .showMapButton {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    span {
      text-align: center;
      cursor: pointer;
      background-color: #3b4aff;
      color: #fff;
      padding: 12px;
      border-radius: 8px;
      font-weight: bold;
      font-size: 16px;
    }
  }
}
/*
@import "themes/dark-theme";
*/
